'use client';
import { Suspense, useEffect } from 'react';
import Script from 'next/script';
import { usePathname, useSearchParams } from 'next/navigation';
import CookieConsent from 'react-cookie-consent';
import { pageview } from '@/app/lib/gtagHelper';

export default function SDCookieConsent({ GA_MEASUREMENT_ID }: { GA_MEASUREMENT_ID: string }) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // GA Tag
  useEffect(() => {
    const url = pathname + searchParams.toString();
    pageview(GA_MEASUREMENT_ID, url);
  }, [pathname, searchParams, GA_MEASUREMENT_ID]);


  return <>
      <Script
        strategy='afterInteractive'
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />
      <Script
        id='google-analytics'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('consent', 'default', {
          'ad_user_data'      : 'denied',
          'ad_personalization': 'denied',
          'ad_storage'        : 'denied',
          'analytics_storage' : 'denied',
          'wait_for_update'   : 500,
        });
        
        gtag('config', '${GA_MEASUREMENT_ID}', {
          page_path: window.location.pathname,
        });
      `,
        }}
      />
    <Suspense fallback={<></>}>
      <CookieConsent
        overlay={true}
        location='bottom'
        buttonText='I understand'
        expires={365}
        disableStyles={true}
        containerClasses='fixed flex justify-between items-end sm:items-center flex-col sm:flex-row gap-4 bg-gray-700 text-white p-3.5 max-w sm:max-w-3xl sm:mb-16 sm:left-4'
        buttonClasses='bg-[#FFD42D] text-black text-nowrap min-w-[120px] py-1 px-2.5'
        onAccept={() => {
          typeof window !== 'undefined' && localStorage.setItem('consentGranted', 'true');
          gtag('consent', 'update', {
            analytics_storage: 'granted',
          });
          // cookieDump()
        }}
      >
        We use essential cookies and storage technologies for website functionality, analyzing usage behaviors, and
        diagnosing technical issues. By continuing to use this website, you agree to this storage.
      </CookieConsent>
    </Suspense>
  </>;
}
