'use client';

import { useEffect } from 'react';
import useAutoRefreshToken from '../hooks/useAutoRefreshToken';
import { isLoggedIn } from '../lib/storageCommon';

export default function JWT() {
  const [startJWTAutoRefresh, stopJWTAutoRefresh] = useAutoRefreshToken();

  useEffect(() => {
    // console.debug('Hook -> JWT.tsx useEffect', 'isLoggedIn', isLoggedIn());
    startJWTAutoRefresh();

    return () => {
      // console.debug('Hook -> JWT.tsx useEffect.return');
      stopJWTAutoRefresh();
    };
  }, [startJWTAutoRefresh, stopJWTAutoRefresh]);

  return <></>;
}
